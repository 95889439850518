import React from "react"
import Layout from "../components/layout"
//import Content_surround from "../components/content_surround"
//import {Link} from "gatsby"

export default function Errorpage() {
  return (
    <Layout image="https://admhotels.com/images/adm_business_1.jpeg">
      <div> 

        <h1 class="text-center">404 Oops...</h1>
        
        <p class="text-center">There's nothing here!</p>



      </div>
    </Layout>



  ) 
}
